// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon {
  fill: #DA4567;
}
.cdk-overlay-container {
  z-index: 9999 !important;
}
.Avertissement
  {font-size: 14px;
  color : black;}
`, "",{"version":3,"sources":["webpack://./src/app/popin-communication/popin-communication.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,wBAAwB;AAC1B;AACA;GACG,eAAe;EAChB,aAAa,CAAC","sourcesContent":[".icon {\r\n  fill: #DA4567;\r\n}\r\n.cdk-overlay-container {\r\n  z-index: 9999 !important;\r\n}\r\n.Avertissement\r\n  {font-size: 14px;\r\n  color : black;}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
