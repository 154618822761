// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progressValue{
  margin: 0 auto;
  width: 100px;
  height: 100px;
  background-color:white;
  border-radius: 3px;
  font-size:xx-large ;
  color: red;
  text-align: center;
  vertical-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/app/popin-sauvegarde-data/popin-sauvegarde-data.component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,mBAAmB;EACnB,UAAU;EACV,kBAAkB;EAClB,sBAAsB;AACxB","sourcesContent":[".progressValue{\r\n  margin: 0 auto;\r\n  width: 100px;\r\n  height: 100px;\r\n  background-color:white;\r\n  border-radius: 3px;\r\n  font-size:xx-large ;\r\n  color: red;\r\n  text-align: center;\r\n  vertical-align: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
