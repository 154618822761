import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


import { Router } from '@angular/router';

interface DialogData {
  InformationData: {
    MessageTitre: string;
    MessageTexte: string;
    Action: string;
    IconeType: string;
    };
}



@Component({
  selector: 'app-modal',
  templateUrl: './popin-communication.component.html',
  styleUrls: ['./popin-communication.component.css']
})
export class PopinCommunicationComponent implements OnInit {

  constructor(
  //  private dataService: DataService,
    public dialogRef: MatDialogRef<PopinCommunicationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {

    }

  onRunClick(): void {
    this.dialogRef.close('RUN');

  }
  onCancelClick(): void {
    this.dialogRef.close('CANCEL');

  }
  onConfirmClick(): void {
    this.dialogRef.close('CONFIRM');

  }
  ngOnInit() {

  }

}



