
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { DataService } from '../data.service';


interface Saison {
  InformationData: {
    Annee : string;
    };
}

@Component({
  selector: 'app-popin-sauvegarde-data',
  templateUrl: './popin-sauvegarde-data.component.html',
  styleUrls: ['./popin-sauvegarde-data.component.css']
})
export class PopinSauvegardeDataComponent implements OnInit {

  codeAbonne: string;
  passwordAbonne : string;
  MessageSauvegarde : string;
  constructor(
    public dataService: DataService,
    private dbService: NgxIndexedDBService,
    private dbServiceRead: NgxIndexedDBService,
    public dialogRef: MatDialogRef<PopinSauvegardeDataComponent>,
    @Inject(MAT_DIALOG_DATA) public YearSelected : Saison)   { }


  ngOnInit(): void {
    this.codeAbonne = "DBON";
    this.passwordAbonne ="aa";
    this.MessageSauvegarde ="";
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onGoClick(): void {
    let listObjSauvegarde = [];
    let yearSelected = '2020';
    if ((yearSelected == '')||(yearSelected == undefined)) {
      yearSelected = new Date().getFullYear()+"";
    }
    //console.log(this.YearSelected);


   this.dbService.getAllByIndex('MyBike','dateEvent', IDBKeyRange.bound(yearSelected+"0101",yearSelected+"1231")).subscribe((evt:any) => {
    evt.forEach(element =>   {
      if (element.ParcoursVelo!=undefined) {
        let parcours = {
            saison :  element.ParcoursVelo.saison,
            dateParcours :  element.ParcoursVelo.dateParcours,
            anneeMois : element.ParcoursVelo.AnneeMois,
            nomParcours : element.ParcoursVelo.NomParcours,
            distance : element.ParcoursVelo.Distance,
            moyenne : element.ParcoursVelo.moyenne,
            duree :  element.ParcoursVelo.duree,
            vitMaxi : element.ParcoursVelo.vitMaxi,
            typeVelo : element.ParcoursVelo.TypeVelo,
            commentaire : element.ParcoursVelo.Commentaire
        }
          let objSauvegarde ={
              parcoursvelo : parcours,
              annee : element.Annee,
              anneemois : element.AnneeMois,
              codeabonne : this.codeAbonne,
              password : this.passwordAbonne
            };
            listObjSauvegarde.push(objSauvegarde);
          }

        })

          this.dataService.postSauvegarde(listObjSauvegarde)
            .subscribe((success)  => {
              this.MessageSauvegarde= success.status;

          });
        });

   // this.dialogRef.close();
  }
}
