import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
//import { MatDatepickerInputEvent } from '@angular/material/datepicker/datepicker-input-base';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { DataService } from './data.service';

/*  import * as MyBikeData from '../assets/sauvegarde_base.json'; */
import * as MyBikeData from '../assets/sauvegarde_base_laurent.json';

// the `default as` syntax.
import * as _moment from 'moment';
import { FormControl } from '@angular/forms';

import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,

} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { DatePipe } from '@angular/common';

import { PopinCommunicationComponent } from './popin-communication/popin-communication.component';
import { AfterViewInit } from '@angular/core';
import { Observable, Subscription, async } from 'rxjs';
import { PopinListeParcoursComponent } from './popin-liste-parcours/popin-liste-parcours.component';
import { PopinSauvegardeDataComponent } from './popin-sauvegarde-data/popin-sauvegarde-data.component';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
//import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
//mport { Alert, AlertPromise } from 'selenium-webdriver';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { type } from 'os';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
//import { ProjectsModalComponent } from './projects-modal/projects-modal.component';

import { formatDate } from "@angular/common";


interface ParcoursVelo  {
  saison : number;
  dateParcours :string;
  AnneeMois: string;
  NomParcours : string;
  Distance : number;
  Duree : string;
  Moyenne : number;
  VitMaxi : number;
  TypeVelo : string;
  Commentaire : string;
};


interface restoreMyBike {
  parcoursVelo:  ParcoursVelo;
 // Annee : string;
 // AnneeMois : string;
 // dateEvent : string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
//  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    {provide: MAT_DATE_LOCALE, useValue: 'ja-JP'},

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class AppComponent   implements OnInit,AfterViewInit  {


  @ViewChild(CdkVirtualScrollViewport) cdkVirtualScrollViewport: CdkVirtualScrollViewport;


  @Input() model: any;
    private updateSubscription: Subscription;
   // items = Array.from({ length: 100 }).map((_, i) => `Item #${i}`);
      items = Array.from({ length: 12 }).map((_, i) => `Item #${i}`);
   // items = Array.from({ length: 12 });
   //items = new Array();
   //   items =null;
    annee = new Date().getFullYear();
    year = 0;
    selected =new Date().getFullYear()+"";
    dateSousTotal : String;

    date = new FormControl(new Date());
    detailMois = [
      {Mois: '.', Total: '.',TotalMoisAnnee: '.'},
    ];
    parent_example : string ='TEST';
    title = 'MyBike';
    events: string[] = [];
    dateParcours = null;
    disabled = false;
    checked = false;
    indeterminate = false;
    parcours ="";
    commentaire ="";
    codeAbonne="";
    refDist:  string;
    totalAnnee: number;
    totalAnneeDiff : number;
    totalAnneeString: string;
    totalAnneeDiffString: string;
    sousTotalAnneeString : string;
    distanceAppliString : string;
    distance : number;
    duree : string;
    moyenne : number;
    vitMaxi : number;
    TypeVelo = localStorage.getItem("TypeVelo");
    parcoursSaisi: ParcoursVelo;
    static selector: any;
    listObjSauvegardeSOS = [];
    stringSauvegardeSOS : string;

    Average_duree : number;
    Average_max : number;
    Average_moy : number;
    classMax : string;
    classDuree : string;
    classMoy : string;
    Nb_occurence_duree : number;
    Nb_occurence_moy : number;
    Nb_occurence_max : number;
    Average_duree_string : string;
    MinNbMinute : number;
    MaxSpeed : number;
    MinAverage : number;
    MinAverage_duree : number;
    MinAverage_duree_string : string;
    Mode_annuel :boolean = true;

    restoreMyBike: any = MyBikeData;
    httpClient: any;
    edited: boolean;

    constructor(private _adapter: DateAdapter<any>,
                private dbService: NgxIndexedDBService,
                private datePipe: DatePipe,
                public dialog: MatDialog,
                private ref: ChangeDetectorRef,
                public dataService: DataService,
                public http:HttpClient) { console.log("init http "+http);
                }
    french() {
      this._adapter.setLocale('fr');
      this.edited = true;

  }
  toggleEvent() {

    this.Mode_annuel = !this.Mode_annuel;
    this.french();
    this.selected = this.annee.toString();
    this.updateTotaux.subscribe({
      next(){},
      }
    )
    this.reloadScreen();
    }
  ngAfterViewInit() {
   setTimeout(() => {



    });
}

  reloadLocalDataBase() {
    //    console.log('Data', this.restoreMyBike.default);
    this.dbService.clear('MyBike');
    let cpt = 0;
    for (const prop in this.restoreMyBike.default)
      {
        cpt = cpt +1;
        var Tmpdate = this.restoreMyBike.default[prop].parcoursvelo.AnneeMois+ this.restoreMyBike.default[prop].parcoursvelo.dateParcours.substr(0,2);
        var TmpAnnee = this.restoreMyBike.default[prop].parcoursvelo.saison;

        var TmpAnneeMois = this.restoreMyBike.default[prop].parcoursvelo.AnneeMois;

        this.dbService
        .add('MyBike', {
            dateEvent : Tmpdate,
            ParcoursVelo:  this.restoreMyBike.default[prop].parcoursvelo,
            Annee : TmpAnnee,
            AnneeMois : TmpAnneeMois
      })
      .subscribe(
        result => {
              console.log("enreg"+result);
        },
        error => {
          console.log(error);
        }
    );
  }
  }

  ngOnInit() {



      this.french();
      this.selected = this.annee.toString();
      this.updateTotaux.subscribe({
        next(){},
        }
      )
      this.reloadScreen();
//      console.log("obelix");
//      console.log(this.date.value);
        if (this.dateParcours == null) {
          this.dateParcours = new Date(); this.date.value
        }

  //    console.log(this.dateParcours );
;

  }



  async AfterloadPage() {


  this.french();
      this.selected = this.annee.toString();
      this.updateTotaux.subscribe({
        next(){},
        }
      )

  }
  async detail(): Promise<void> {
    let listeVelo : Array<string>=[];

    this.dbService.getAllByIndex('MyBike','dateEvent', IDBKeyRange.bound(this.selected+"0101",this.selected+"1231")).subscribe((evt:any) => {
      evt.forEach(element =>   { if (element.ParcoursVelo!=undefined) {
        if ((element.ParcoursVelo.Distance!=0) && (element.ParcoursVelo.Distance !=undefined)) {
            element.ParcoursVelo.Distance = parseFloat(element.ParcoursVelo.Distance.replace(/,/, '.')).toFixed(1);
   //         console.log(element.ParcoursVelo);
            listeVelo.push(element.ParcoursVelo);
         //   console.log(element.ParcoursVelo.Commentaire);
        }
      }
    })

      const dialogListe = this.dialog.open(PopinListeParcoursComponent, {
        width: '95%', height: '90%',
        // tslint:disable-next-line: max-line-length
        data: {InformationData:listeVelo}
      });
      dialogListe.afterClosed().subscribe(result => {
        if (result != undefined) {
          if (result != 'RUN') {
            console.log(result);

this.dateParcours = result;

var newarr = result.split("/");


            let dateParcoursString ="";
      //      let dateParcoursString = this.datePipe.transform(result,"dd/MM/yyyy");

            console.log("dateParcoursString");
         //   let dateString = this.datePipe.transform(result,"yyyyddMM");
         let dateString ="";


  const year =newarr[2];
  const month = newarr[1];
  const day = newarr[0];
  dateParcoursString = year+month+day;
  this.dateParcours = month+"/"+day+"/"+year;
  ///          this.date = new Date(year, month - 1, day);
 //           this.date = new FormControl(new Date(this.datePipe.transform(result,"yyyyMMdd")) );
            this.date = new FormControl(new Date(new Date(year, month - 1, day)));
            this.dbService.getByIndex('MyBike','dateEvent',dateParcoursString).subscribe((evt:any) => {
              console.log(evt);

              if (evt != undefined)
                {
                  if (evt.ParcoursVelo!=undefined) {
                    console.log(evt);
             //       this.distance =  this.check_valeur(evt.ParcoursVelo.Distance);
                    this.parcours =  evt.ParcoursVelo.NomParcours;
                    this.distance =  this.check_valeur(evt.ParcoursVelo.Distance);
                    this.moyenne = evt.ParcoursVelo.Moyenne;
                    this.duree =  this.converterHHMMSS_time(evt.ParcoursVelo.Duree);
                    this.vitMaxi = evt.ParcoursVelo.VitMaxi;
                    this.TypeVelo = evt.ParcoursVelo.TypeVelo;
                    this.commentaire = evt.ParcoursVelo.Commentaire;
                   }
                }
              });
            }
        }
      });
    });


    }

check_valeur(obj: any)
    {
      if (obj==undefined) return 0; else return obj;
    }
    async export() {
      let listeVelo : Array<string>=[];
      const dialogListe = this.dialog.open(PopinSauvegardeDataComponent, {
        width: '95%', height: '40%',
        // tslint:disable-next-line: max-line-length

        data: {InformationData:this.selected}
      });

    }
    async reload() {
    //  console.log("AAA 11");
    //  this.dbService.clear('MyDb');
   //   console.log("BBBB A1");
    this.dataService.Reload(this.dbService);
    }
    async SendMailSos() {

      location.href="mailto:sauvegarde@mybikeexpressobelix.fr?body="+encodeURIComponent(JSON.stringify(this.listObjSauvegardeSOS));
    }
    async export_sos() {

      let listeVelo : Array<string>=[];
      this.stringSauvegardeSOS ="";
      this.dbService.getAllByIndex('MyBike','dateEvent', IDBKeyRange.bound("20000101","20991231")).subscribe((evt:any) => {
        evt.forEach(element =>   {
          if (element.ParcoursVelo!=undefined) {
            let parcours = {
                saison :  element.ParcoursVelo.saison,
                dateParcours :  element.ParcoursVelo.dateParcours,
                AnneeMois : element.ParcoursVelo.AnneeMois,
                NomParcours : element.ParcoursVelo.NomParcours,
                Distance : element.ParcoursVelo.Distance,
                Moyenne : element.ParcoursVelo.Moyenne,
                Duree : element.ParcoursVelo.Duree,
                VitMaxi : element.ParcoursVelo.VitMaxi,
                TypeVelo : element.ParcoursVelo.TypeVelo,
                Commentaire : element.ParcoursVelo.Commentaire

            }


           let objSauvegarde ={
                  parcoursvelo : parcours,
                  Annee : element.Annee,
                  Anneemois : element.AnneeMois,
                  dateEvent : element.dateEvent

                };
;


  //  this.dataService.SauvegardeCloud(this.codeAbonne,objSauvegarde.dateEvent, JSON.stringify(objSauvegarde.parcoursvelo) ,objSauvegarde.Annee,objSauvegarde.Anneemois);
                this.listObjSauvegardeSOS.push(objSauvegarde);
              }

            })


          })
          const dialogRef = this.dialog.open(PopinCommunicationComponent, {
      width: '300px', height: '250px',
      // tslint:disable-next-line: max-line-length
      data: {InformationData: {MessageTitre: 'Information', MessageTexte: 'Sauvegarde disponible', Action: 'Confirm', IconeType: ''}}
    } as any);     }

    async exportTotalCloud() {

      this.stringSauvegardeSOS ="";
      this.dbService.getAllByIndex('MyBike','dateEvent', IDBKeyRange.bound("20000101","20991231")).subscribe((evt:any) => {
        evt.forEach(element =>   {
          if (element.ParcoursVelo!=undefined) {
            let parcours = {
                saison :  element.ParcoursVelo.saison,
                dateParcours :  element.ParcoursVelo.dateParcours,
                AnneeMois : element.ParcoursVelo.AnneeMois,
                NomParcours : element.ParcoursVelo.NomParcours,
                Distance : element.ParcoursVelo.Distance,
                Moyenne : element.ParcoursVelo.Moyenne,
                Duree : element.ParcoursVelo.Duree,
                VitMaxi : element.ParcoursVelo.VitMaxi,
                TypeVelo : element.ParcoursVelo.TypeVelo,
                Commentaire : element.ParcoursVelo.Commentaire

            }


           let objSauvegarde ={
                  parcoursvelo : parcours,
                  Annee : element.Annee,
                  Anneemois : element.AnneeMois,
                  dateEvent : element.dateEvent

                };
;


    this.dataService.SauvegardeCloud(this.codeAbonne,objSauvegarde.dateEvent, JSON.stringify(objSauvegarde.parcoursvelo) ,objSauvegarde.Annee,objSauvegarde.Anneemois);

              }

            })


          })
          const dialogRef = this.dialog.open(PopinCommunicationComponent as any, {
            width: '300px', height: '250px',
            // tslint:disable-next-line: max-line-length
            data: {InformationData: {MessageTitre: 'Information', MessageTexte: 'Sauvegarde cloud effectuée', Action: 'Confirm', IconeType: ''}}
          } as any);

  }

    async     initSousTotal() {
 //     console.log(this.datePipe.transform(this.dateParcours,"yyyyMMdd"));
      if (this.dateParcours == null) {
        this.dateParcours=new Date();
      }

      localStorage.setItem("dateSousTotal",this.datePipe.transform(this.dateParcours,"yyyyMMdd"));
      localStorage.setItem("dateSousTotalAff",this.datePipe.transform(this.dateParcours,"dd/MM/YYYY"));
      this.updateTotaux.subscribe({
        next(){},
        });
    }

//https://github.com/assuncaocharles/ngx-indexed-db

    async sauvegarde(): Promise<void> {

      localStorage.setItem("codeAbonne",this.codeAbonne);
      localStorage.setItem("refDist",this.refDist);
        localStorage.setItem("TypeVelo",this.TypeVelo);


        let dateParcoursTemp = this.datePipe.transform(this.dateParcours,"dd/MM/yyyy");
        var newarr = dateParcoursTemp.split("/");
        const year =newarr[2];
        const month = newarr[1];
        const day = newarr[0];

        this.parcoursSaisi ={
          saison : Number(year), //parseInt(this.datePipe.transform(this.dateParcours,"yyyy")),
          dateParcours : day+"/"+month+"/"+year, //this.datePipe.transform(this.dateParcours,"dd/MM/yyyy"),
          AnneeMois : year+month, // this.datePipe.transform(this.dateParcours,"yyyyMM"),
          Distance : this.distance,
          Duree : this.duree.replace(":","H")+"mn",
          Moyenne : this.moyenne,
          VitMaxi : this.vitMaxi,
          Commentaire : this.commentaire,
          NomParcours : this.parcours,
          TypeVelo : this.TypeVelo }

          this.dbService
            .add('MyBike', {
                dateEvent :  year+month+day, // this.datePipe.transform(this.dateParcours,"yyyyMMdd"),
                ParcoursVelo:  this.parcoursSaisi,
                Annee : year, //this.annee,
                AnneeMois : year+month //this.datePipe.transform(this.dateParcours,"yyyyMM")
          })
          .subscribe(
            result => {
              const dialogRef = this.dialog.open(PopinCommunicationComponent as any, {
                width: '300px', height: '250px',
                // tslint:disable-next-line: max-line-length
                data: {InformationData: {MessageTitre: 'Information', MessageTexte: 'Parcours enregistré', Action: 'Confirm', IconeType: ''}}
              } as any);
              this.dataService.SauvegardeCloud(this.codeAbonne,this.datePipe.transform(this.dateParcours,"yyyyMMdd"),JSON.stringify(this.parcoursSaisi),this.annee,this.datePipe.transform(this.dateParcours,"yyyyMM"));
            },
            error => {
              console.log(error);
              const dialogRef = this.dialog.open(PopinCommunicationComponent as any, {
                width: '300px', height: '250px',
                // tslint:disable-next-line: max-line-length
                data: {InformationData: {MessageTitre: 'Avertissement', MessageTexte: 'Voulez vous remplacer le parcours existant?', Action: 'Confirm|Cancel', IconeType: ''}}
              }) as any;
              dialogRef.afterClosed().subscribe(result => {
                if (result === 'RUN') {

        let dateParcoursString = this.datePipe.transform(this.dateParcours,"yyyyMMdd")

                  this.dbService.update('MyBike', {
                        dateEvent :  year+month+day, //this.datePipe.transform(this.dateParcours,"yyyyMMdd"),
                        ParcoursVelo:  this.parcoursSaisi,
                        Annee : year, //dateParcoursString.substring(0,4),
                        AnneeMois : year+month //dateParcoursString.substring(0,6)
                      })

                  .subscribe(
                    result => {
                      this.updateTotaux.subscribe({
                        next(){},
                        });
          //              console.log("update cloud");
                        this.dataService.UpdateCloud(this.codeAbonne,this.datePipe.transform(this.dateParcours,"yyyyMMdd"),JSON.stringify(this.parcoursSaisi),this.annee,this.datePipe.transform(this.dateParcours,"yyyyMM"));

            //            this.dataService.SauvegardeCloud();
                      const dialogRef = this.dialog.open(PopinCommunicationComponent as any, {
                        width: '300px', height: '250px',
                        // tslint:disable-next-line: max-line-length
                        data: {InformationData: {MessageTitre: 'Information', MessageTexte: 'Parcours enregistré', Action: 'Confirm', IconeType: ''}}
                      });

                    },
                    error => {
                          console.log(error);
                  });
              }
          });
            }


        );
     //     this.updateTotaux("Après mise à jour");
 /*    const dialogRef = this.dialog.open(PopinCommunicationComponent, {
      width: '300px', height: '250px',
      // tslint:disable-next-line: max-line-length
      data: {InformationData: {MessageTitre: 'Information', MessageTexte: 'Parcours enregistré', Action: 'Confirm', IconeType: ''}}
    }); */
      this.updateTotaux.subscribe({
        next(){},
        }
      )

  }


  reloadScreen() {
      this.codeAbonne = localStorage.getItem("codeAbonne");
      this.refDist= localStorage.getItem("refDist");
      this.dbService.getByIndex('MyBike','dateEvent',this.datePipe.transform(new Date(), 'yyyyMMdd')).subscribe((evt:any) => {
      if (evt != undefined)
        {
          if (evt.ParcoursVelo!=undefined) {
            this.parcours =  evt.ParcoursVelo.NomParcours;
            this.distance =  this.check_valeur(evt.ParcoursVelo.Distance);
            this.moyenne = evt.ParcoursVelo.Moyenne;
            this.duree =  this.converterHHMMSS_time(evt.ParcoursVelo.Duree);
            this.vitMaxi = evt.ParcoursVelo.VitMaxi;
            this.TypeVelo = evt.ParcoursVelo.TypeVelo;
            this.commentaire = evt.ParcoursVelo.Commentaire;
            let moisParcours = parseInt(this.datePipe.transform(new Date(), 'MM'))-1;
            this.cdkVirtualScrollViewport.scrollToIndex(moisParcours*2);
           }
        }
      });


  }
  converterHHMMSS_time(tempo : string): string
  {
    if (tempo!=undefined) {
    let nbMinute = 0;
    let duree = tempo.toUpperCase();
    if (duree.indexOf("H")!=-1) {
      let nbHeure = parseFloat(duree.substr(0,duree.indexOf("H")));
      nbMinute = nbHeure*60;
       if ((duree.indexOf("M")!=-1) ) {

        let tempoMn = parseFloat(duree.substring(duree.indexOf("H")+1,duree.indexOf("M")));
        nbMinute = nbMinute + tempoMn;
      } else {
        let tempoMn = parseFloat(duree.substring(duree.indexOf("H")+1));
        nbMinute = nbMinute + tempoMn;
      }
    } else {
      nbMinute = parseFloat(duree.substring(0,duree.indexOf("M")));
    }

    var num = nbMinute;
    var hours = (num / 60);

    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);

    const str1 = rhours+"";
    const str2 = rminutes+"";

    return str1.padStart(2, '0') + ":" + str2.padStart(2, '0');
  }
  }
  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {


    this.events.push(`${type}: ${event.value}`);
    this.dateParcours = event.value;

    let moisParcours = parseInt(this.datePipe.transform(event.value, 'MM'));

    document.getElementById("saison").scrollTop = (moisParcours-1)*(360.0/12.0);
    this.dateParcours = this.datePipe.transform(event.value, 'MM/dd/yyyy');
    console.log( "   this.dateParcours" );
    console.log(    this.dateParcours );

    this.dbService.getByIndex('MyBike','dateEvent',this.datePipe.transform(event.value, 'yyyyMMdd')).subscribe((evt:any) => {
    if (evt != undefined)
      {
        if (evt.ParcoursVelo!=undefined) {
   //       this.distance =  evt.ParcoursVelo.Distance;
          this.parcours =  evt.ParcoursVelo.NomParcours;
          this.distance =  this.check_valeur(evt.ParcoursVelo.Distance);
          this.moyenne = evt.ParcoursVelo.Moyenne;
          this.duree =  evt.ParcoursVelo.Duree;
          this.duree = this.converterHHMMSS_time(this.duree);

          this.vitMaxi = evt.ParcoursVelo.VitMaxi;
          this.TypeVelo = evt.ParcoursVelo.TypeVelo;
          this.commentaire = evt.ParcoursVelo.Commentaire;
         }
      }
      else
      {
        this.distance =  0;
        this.parcours =  '';
        this.distance =  0;
        this.moyenne = 0;
        this.duree =  '';
        this.vitMaxi = 0;
        this.TypeVelo = '';
        this.commentaire = '';
      }

    });
    this.updateTotaux.subscribe({
      next(){},
      });
  }



  addEventSelect(type: MatSelectChange) {
    this.dateParcours = new Date(type.value+"/"+this.datePipe.transform(new Date(), 'MM/dd'));
    //alert(type.value+"/"+this.datePipe.transform(new Date(), 'MM/dd'));
    this.date = new FormControl(new Date(type.value+'/'+this.datePipe.transform(new Date(), 'MM/dd')));
    let moisParcours = parseInt(this.datePipe.transform(new Date(), 'MM'));
    document.getElementById("saison").scrollTop = (moisParcours-1)*(360/12);
    this.dbService.getByIndex('MyBike','dateEvent',this.datePipe.transform(this.dateParcours,'yyyyMMdd')).subscribe((evt:any) => {
    if (evt != undefined)
      {

        if (evt.ParcoursVelo!=undefined) {

     //     this.distance =  evt.ParcoursVelo.Distance;
          this.parcours =  evt.ParcoursVelo.NomParcours;
          this.distance =  this.check_valeur(evt.ParcoursVelo.Distance);

          this.moyenne = evt.ParcoursVelo.Moyenne;
          this.duree =  evt.ParcoursVelo.Duree;
          this.duree = this.converterHHMMSS_time(this.duree);
          this.vitMaxi = evt.ParcoursVelo.VitMaxi;
          this.TypeVelo = evt.ParcoursVelo.TypeVelo;
          this.commentaire = evt.ParcoursVelo.Commentaire;
         }
      }
      else
      {
        this.distance =  0;
        this.parcours =  '';
        this.distance =  0;
        this.moyenne = 0;
        this.duree =  '';
        this.vitMaxi = 0;
        this.TypeVelo = '';
        this.commentaire = '';
      }

    });
    this.updateTotaux.subscribe({
      next(){},
      }
    )
  }

  @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;


   updateTotaux = new Observable((observer) => {
    let distanceAnnee = 0;
    let distanceAppli = 0;
    let detailMois = [0,0,0,0,0,0,0,0,0,0,0,0];
    let totalMois = [0,0,0,0,0,0,0,0,0,0,0,0];
    let libMois = ['Janvier','Fevrier','Mars','Avil','Mai',"Juin","Juillet",'Aout','Septembre','Octobre','Novembre','Decembre'];
    let espaceMois =  ['','','','','',"","",'','','','',''];
    let tempoDetailMois =[];
    if (this.selected == '') {
      this.selected = new Date().getFullYear()+"";
    }
    //    let moisParcours = parseInt(this.datePipe.transform(new Date()-365, 'MM'))-1;
    //console.log(this.datePipe.transform(new Date(), 'YYYYMMdd'));
    var y = new Date();
    //console.log(y.getFullYear());
    y.setFullYear(y.getFullYear() - 1);

    var date_min = "";
    var date_max = "";
       if (this.Mode_annuel==true)
        {
          date_min = this.selected+"0101";
          date_max = this.selected+"1231";
        }
    else
      {
        date_max = this.datePipe.transform(new Date(), 'yyyyMMdd');
        var y = new Date();
        y.setFullYear(y.getFullYear() - 1);
        date_min = this.datePipe.transform(y, 'yyyyMMdd');
      }


    this.dbService.getAllByIndex('MyBike','dateEvent', IDBKeyRange.bound(date_min,date_max)).subscribe((evt:any) => {
          let sousTotal = 0;
          this.dateSousTotal = localStorage.getItem("dateSousTotalAff");
          evt.forEach(element =>
             { if (element.ParcoursVelo!=undefined) {

   //         console.log(element.ParcoursVelo.Distance);
            if (element.ParcoursVelo.Distance!=0 && element.ParcoursVelo.Distance!=undefined) {
      //        console.log(element.ParcoursVelo.Distance.replace(/,/, '.'));
                distanceAnnee = distanceAnnee + parseFloat(this.check_valeur(element.ParcoursVelo.Distance.replace(/,/, '.')));
            }
            let mois =parseFloat(element.dateEvent.substring(4,6))-1;
    //        console.log(element.ParcoursVelo.Distance);
            detailMois[mois] = detailMois[mois] +parseFloat(element.ParcoursVelo.Distance);
            if (mois!=0) {
              totalMois[mois] = totalMois[mois-1] +  detailMois[mois];
            } else {
              if (element.ParcoursVelo.Distance!=0 && element.ParcoursVelo.Distance!= undefined) {
                totalMois[mois] = totalMois[mois]+ parseFloat(element.ParcoursVelo.Distance.replace(/,/, '.'));
              }
            }
//            console.log(element.dateEvent+" "+localStorage.getItem("dateSousTotal"));
            if (element.dateEvent >=localStorage.getItem("dateSousTotal")) {
              if (element.ParcoursVelo.Distance!=0 && element.ParcoursVelo.Distance!=undefined) {
                  sousTotal = sousTotal + parseFloat(element.ParcoursVelo.Distance.replace(/,/, '.'));
              }
            }
    //        console.log(element.dateEvent+"mois + "+mois + '+'+parseFloat(element.ParcoursVelo.Distance)+'+'+detailMois[mois]);
};


        });

        totalMois[0] =  detailMois[0];
        for(i=1;i<12;i++) {
          totalMois[i] = totalMois[i-1] +  detailMois[i];
        }
        let nb_occurence_moy=0;
        let average_moy = 0.0;
        let nb_occurence_max=0;
        let average_max = 0.0;
        let nb_occurence_duree=0;
        let average_duree = 0.0;
        let nbMinute = 0;
        this.MinNbMinute = 100000;
        this.MaxSpeed = -100;
        this.MinAverage = -10000;
    //    let TabTypeVelo = ["","Nakamura"];
        this.dbService.getAllByIndex('MyBike','dateEvent',null).subscribe((evt:any) => {
          evt.forEach(element =>   { if (element.ParcoursVelo!=undefined) {

                                          if ((element.ParcoursVelo.NomParcours ==this.parcours)&&(element.ParcoursVelo.TypeVelo ==this.TypeVelo)){
                                            if ((element.ParcoursVelo.Moyenne != null)) {
                                              if ((!Number.isNaN(element.ParcoursVelo.Moyenne))) {
                                                  nb_occurence_moy+=1;
                                                  let tempo_moyenne = element.ParcoursVelo.Moyenne+"";
                                                  let bid_moyenne =  parseFloat(tempo_moyenne.replace(/,/, '.'));

                                                  if ((bid_moyenne > this.MinAverage) && (bid_moyenne !=0)) {
                                                    this.MinAverage = bid_moyenne;
                                                  }
                                                  average_moy = average_moy + parseFloat(tempo_moyenne.replace(/,/, '.'));
                                          }
                                        }

                                          if ( element.ParcoursVelo.VitMaxi!=undefined) {
                                            if ( element.ParcoursVelo.VitMaxi!=0) {
                                            if ((element.ParcoursVelo.VitMaxi != null)&&(!Number.isNaN(element.ParcoursVelo.VitMaxi))) {
                                              nb_occurence_max+=1;
                                              let bid_moyenne =  parseFloat(element.ParcoursVelo.VitMaxi.replace(/,/, '.'));
                                              average_max = average_max + bid_moyenne;
                                              if (bid_moyenne >  this.MaxSpeed) {
                                                this.MaxSpeed = bid_moyenne;
                                    //            console.log("this.MaxSpeed "+this.MaxSpeed);
                                              }
                                            }
                                          }
                                        }

                                          if ((element.ParcoursVelo.Duree != null) && (element.ParcoursVelo.Duree !="")) {
                                              nb_occurence_duree +=1;
                                  //            console.log("dureeeXXXX = "+element.ParcoursVelo.Duree);
                                              let duree = element.ParcoursVelo.Duree.toUpperCase();
                                              if (duree.indexOf("H")!=-1) {
                                                let nbHeure = parseFloat(duree.substr(0,duree.indexOf("H")));
                                                nbMinute = nbHeure*60;
                                                 if ((duree.indexOf("M")!=-1) ) {

                                                  let tempoMn = parseFloat(duree.substring(duree.indexOf("H")+1,duree.indexOf("M")));
                                                  nbMinute = nbMinute + tempoMn;
                                                } else {
                                                  let tempoMn = parseFloat(duree.substring(duree.indexOf("H")+1));
                                                  nbMinute = nbMinute + tempoMn;
                                                }
                                              } else {
                                                nbMinute = parseFloat(duree.substring(0,duree.indexOf("M")));
                                              }

                                              if (!Number.isNaN(nbMinute)) {
                                                average_duree = average_duree +nbMinute;
                                    //            console.log("avg " +average_duree);
                                                if (nbMinute < this.MinNbMinute) {
                                                  this.MinNbMinute = nbMinute;
                                                }
                                              }

                                          }
                                        }
                                        if ((element.ParcoursVelo.Distance!=0) && (element.ParcoursVelo.Distance !=undefined)) {
                           //               console.log(element.ParcoursVelo.Distance);
                                                 distanceAppli = distanceAppli + parseFloat(this.check_valeur(element.ParcoursVelo.Distance.replace(/,/, '.')));
                                        }
                                           }});
                                           this.distanceAppliString =  distanceAppli.toFixed(1);

                                           if ((this.duree != null) && (this.duree !="")&&(this.duree !=undefined)) {
                                            let duree = this.duree.toUpperCase();
                                            if (duree.indexOf(":")!=-1) {
                                              let nbHeure = parseFloat(duree.substr(0,duree.indexOf(":")));

                                              nbMinute = nbHeure*60;
                                                let tempoMn = parseFloat(duree.substring(duree.indexOf(":")+1));

                                                nbMinute = nbMinute + tempoMn;

                                              }


                                  //          this.Average_duree = this.Average_duree +nbMinute;

                                        }
                                        this.Nb_occurence_duree = nb_occurence_duree;
                                        this.Nb_occurence_moy = nb_occurence_moy;
                                        this.Nb_occurence_max = nb_occurence_max;

                          //             this.classDuree ="mat-input-element mat-form-field-autofill-control ng-untouched ng-pristine ng-valid cdk-text-field-autofill-monitored rgb(0, 0, 0)";
                                        this.classDuree="";
                                        if ((nb_occurence_duree > 1)&&(this.duree !=undefined)) {
                                              this.Average_duree = average_duree/nb_occurence_duree;

                                              if (this.Average_duree>nbMinute)  this.classDuree = "rgb(0, 255, 0)";
                                              if (this.Average_duree<nbMinute)  this.classDuree = "rgb(255, 100, 100)";
                                              if (this.Average_duree >=60) {
                                                this.Average_duree_string = Math.floor(this.Average_duree / 60).toString()+"H"+(this.Average_duree-Math.floor(this.Average_duree / 60)*60).toFixed(0).toString()+"mn";
                                              }
                                              else {
                                                this.Average_duree_string = (this.Average_duree.toFixed(0)).toString()+"mn";

                                              }
                                      //        console.log("this.MinNbMinute" +this.MinNbMinute);
                                              if (this.MinNbMinute >=60) {
                                                this.MinAverage_duree_string = Math.floor(this.MinNbMinute / 60).toString()+"H"+(this.MinNbMinute-Math.floor(this.MinNbMinute / 60)*60).toFixed(0).toString()+"mn";
                                              }
                                              else {
                                                this.MinAverage_duree_string = (this.MinNbMinute.toFixed(0)).toString()+"mn";

                                              }

                                           }
                                           this.Average_moy = -1;
                                           this.classMoy ="mat-input-element mat-form-field-autofill-control ng-untouched ng-pristine ng-valid cdk-text-field-autofill-monitored rgb(0, 0, 0)";
                                           this.classMoy ="";
                                           if (nb_occurence_moy > 1) {
                                              this.Average_moy = average_moy/nb_occurence_moy;
                                              if (this.Average_moy<this.moyenne)  this.classMoy = "rgb(0, 255, 0)";
                                              if (this.Average_moy>this.moyenne)  this.classMoy = "rgb(255, 100, 100)";
                                           }
                                           this.Average_max = -1;
                                           this.classMax ="mat-input-element mat-form-field-autofill-control ng-untouched ng-pristine ng-valid cdk-text-field-autofill-monitored rgb(0, 0, 0)";
                                           this.classMax="";
                                           if (nb_occurence_max > 1) {
                                              this.Average_max = average_max/nb_occurence_max;
                                              if (this.Average_max<this.vitMaxi)  this.classMax = "rgb(0, 255, 0)";
                                              if (this.Average_max>this.vitMaxi)  this.classMax = "rgb(255, 100, 100)";
                                           }

                                //           console.log(this.Average_max + "this.Average_max "+average_max+ " "+nb_occurence_max);
                                          });



        if (distanceAnnee != undefined) {
          this.totalAnnee =distanceAnnee;
          if (!this.Mode_annuel)
          {
 //           console.log(this.refDist);
            this.totalAnneeDiff= distanceAnnee-parseFloat(this.refDist);
            this.totalAnneeDiffString = this.totalAnneeDiff.toFixed(1);
            this.totalAnnee =distanceAnnee;
          }
        } else {
          this.totalAnnee =0;
        }
        this.totalAnneeString = this.check_valeur(this.totalAnnee.toFixed(1));
        this.sousTotalAnneeString = this.check_valeur(sousTotal.toFixed(1));

        for(var i=0;i<12;i++) {
          let bid = null;
          if (detailMois!=undefined) {
            bid = null;
       //     if (detailMois[i] !=0) {
              bid =  {Mois: libMois[i], Total: detailMois[i].toFixed(1), TotalMoisAnnee :totalMois[i].toFixed(1)};
              let bidule = libMois[i]+detailMois[i].toFixed(1);
              espaceMois[i]=totalMois[i].toFixed(1).padStart(25-bidule.length,'.');

       //     }
            tempoDetailMois.push(bid);
          } else {
            let bid =  {Mois: libMois[i], Total: 0};
            tempoDetailMois.push(bid);
          }


      //    this.items.push(bid);
   //       this.items.push("test");
        }
        this.detailMois = tempoDetailMois;

 //       this.items = Array.from({ length: 12 }).map((_, i) => `${tempoDetailMois[i].value`));
 this.items = Array.from({ length: 12 }).map((_, i) => ` ${libMois[i]} ${detailMois[i].toFixed(1)} ${espaceMois[i]}  `);

 //       this.cdkVirtualScrollViewport.scrollToIndex(24);
 let moisParcours = parseInt(this.datePipe.transform(new Date(), 'MM'))-1;
 this.cdkVirtualScrollViewport.scrollToIndex(moisParcours*2);


      });


  //    document.getElementById("saison").scrollTop = (moisParcours-1)*(360/12);


      this.ref.markForCheck();
      observer.next;
    }
    )
    async loadData(): Promise<void> {


 /*     this.dbService.getAll("MyBike")
      .subscribe(
        result  => {

          var output = [];
          for(let object  of result) {
            var row = [];
            for(var prop in object) {
              this.listObjSauvegardeSOS.push(to_csv_value(object[prop]));
              this.listObjSauvegardeSOS.push(';');
       //      console.log(object[prop]);
        }
      }
//      console.log("this.listObjSauvegardeSOS.toString");
//      console.log(this.listObjSauvegardeSOS);
    }
        ,
                    error => {
                          console.log(error);
                  }); */
    }
//  return output.join('');
      }

      function to_csv_value(value: string) {
        var output = '"';
        output += value;
        return value + '"';};







