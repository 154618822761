import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MaterialModule } from './material-module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';
import { DatePipe } from '@angular/common';

import { PopinCommunicationComponent } from './popin-communication/popin-communication.component';
import { Routes, RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { PopinListeParcoursComponent } from './popin-liste-parcours/popin-liste-parcours.component';
import { PopinSauvegardeDataComponent } from './popin-sauvegarde-data/popin-sauvegarde-data.component';
import { HttpClientModule } from '@angular/common/http';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

// Ahead of time compiles requires an exported function for factories
export function migrationFactory() {
  // The animal table was added with version 2 but none of the existing tables or data needed
  // to be modified so a migrator for that version is not included.
  return {
    1: (db, transaction) => {
      const store = transaction.objectStore('MyBike');
      store.createIndex('dateEvent', 'dateEvent', { unique: true });
    }
  };
}

const routes: Routes = [];

// https://developer.aliyun.com/mirror/npm/package/ngx-indexed-db/v/5.0.1
const dbConfig: DBConfig  = {
  name: 'MyDb',
  version: 1,
  objectStoresMeta: [{
    store: 'MyBike',
    storeConfig: { keyPath: 'dateEvent', autoIncrement: false,  options: { unique: true } },
    storeSchema: [
      { name: 'ParcourVelo', keypath: 'ParcourVelo', options: { unique: false } },
      { name: 'Annee', keypath: 'Annee', options: { unique: false } },
      { name: 'AnneeMois', keypath: 'AnneeMois', options: { unique: false } },
    ]
  }],
  // provide the migration factory to the DBConfig
  migrationFactory
};


@NgModule({
 // entryComponents: [
 //   PopinCommunicationComponent,
  //  PopinListeParcoursComponent,
  //  PopinSauvegardeDataComponent

  declarations: [
    AppComponent,
    PopinCommunicationComponent,
    PopinListeParcoursComponent,
    PopinSauvegardeDataComponent,
  ],
  exports: [RouterModule],
  imports: [
    MatFormFieldModule,
    MatInputModule,
    BrowserModule,
    MaterialModule,
    FormsModule,
    BrowserAnimationsModule,
    NgxIndexedDBModule.forRoot(dbConfig),
    RouterModule.forRoot(routes),
    AppRoutingModule,
    HttpClientModule,
    ScrollingModule,
    MatSelectModule
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})


export class AppModule {
  }










