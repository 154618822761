import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppComponent } from '../app.component';


interface DialogData {
  InformationData: {
   evt: Array<object>;
    };
}


@Component({
  selector: 'app-popin-liste-parcours',
  templateUrl: './popin-liste-parcours.component.html',
  styleUrls: ['./popin-liste-parcours.component.css']
})
export class PopinListeParcoursComponent implements OnInit {

  constructor(
    //  private dataService: DataService,
      public dialogRef: MatDialogRef<PopinListeParcoursComponent>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData) {

      }
filtrage : string;

    onRunClick(): void {
      this.dialogRef.close('RUN');

    }
    onCancelClick(): void {
      this.dialogRef.close('CANCEL');

    }
    onConfirmClick(): void {
      this.dialogRef.close('CONFIRM');

    }
    ngOnInit() {

    }
    addEventListe(type: string) : void {

      this.dialogRef.close(type);
      }
      filtragePerso(InformationData : any, filtre : string) : Array<string> {
        let listeVelo : Array<string>=[];
        if (filtre != null) filtre = filtre.toUpperCase();
        InformationData.forEach(function (value) {
          let bid =value.NomParcours.toUpperCase();
 //         console.log(filtre +" " +bid+ " "+bid.indexOf(filtre));
          if ((bid.indexOf(filtre)!=-1)||(filtre=='')||(filtre==null)) {
            listeVelo.push(value);
          }
     });
        return listeVelo;
}
}


