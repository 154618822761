// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ListeParcours
  {font-size: 14px;
  color : black;}

  .ref{
    height: 30px;
    font-size: 20px;
   }
`, "",{"version":3,"sources":["webpack://./src/app/popin-liste-parcours/popin-liste-parcours.component.css"],"names":[],"mappings":"AAAA;GACG,eAAe;EAChB,aAAa,CAAC;;EAEd;IACE,YAAY;IACZ,eAAe;GAChB","sourcesContent":[".ListeParcours\r\n  {font-size: 14px;\r\n  color : black;}\r\n\r\n  .ref{\r\n    height: 30px;\r\n    font-size: 20px;\r\n   }\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
